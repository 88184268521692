const generateWhatsappLink = ({ phone_number, message, link }) => {
  if (phone_number && message) {
    // todo: use regex
    let formattedPhoneNumber = phone_number.replace('+2', '2')?.replace('002', '2');
    if (!/^[2]/.test(formattedPhoneNumber)) {
      formattedPhoneNumber = `2${formattedPhoneNumber}`;
    }
    return `https://api.whatsapp.com/send/?phone=${formattedPhoneNumber}&text=${message}
    ${link ? link : ''}
    `;
  } else {
    return '';
  }
};
export default generateWhatsappLink;