import { useEffect, useMemo, useState } from 'react';

import generateTelegramLink from './utils/generateTelegramLink';
import generateWhatsappLink from './utils/generateWhatsappLink';
import getDeviceType from './utils/getDeviceType';

// import logo from './assets/images/Logo@2x.png';
// import bannerBg from './assets/images/Path 19310@2x.png';
// import bannerImg from './assets/images/vector@2x.png';
import cezmaLogo from './assets/images/cezma.png';
import downloadButton from './assets/images/download@2x.png';
import whatsappIcon from './assets/icons/whatsapp.svg';
import vectorImage from './assets/images/vector.png';
import desktopImg from './assets/images/Mobile browsers-cuate@2x.png';
// import whatsappIcon from './assets/icons/whatsapp.svg';
import telegramIcon from './assets/icons/telegram.svg';
import facebookIcon from './assets/icons/facebook.svg';
import instaIcon from './assets/icons/insta.svg';
import text from './assets/images/text.png';
import WhatsappButtonIcon from './assets/images/whatsapp@2x.png'
import PhoneIcon from './assets/images/phone.png';

import { MetaPixel } from './components';

function App() {
  const [layoutData, setLayoutData] = useState({});
  const [viewCount, setViewCount] = useState(0);
  const [downloadCount, setDownloadCount] = useState(0);

  const socialMedia = useMemo(
    () =>
      [
        {
          icon: whatsappIcon,
          link: generateWhatsappLink({ phone_number: layoutData?.social_media?.whatsapp, message: 'انا مستخدم من موقع سيزما، اريد الاستفسار عن شئ', link: window.location.href || '' }),
          data: layoutData?.social_media?.whatsapp,
        },
        {
          icon: telegramIcon,
          link: generateTelegramLink(layoutData?.social_media?.telegram),
          data: layoutData?.social_media?.telegram,
        },
        {
          icon: facebookIcon,
          link: layoutData?.social_media?.facebook,
          data: layoutData?.social_media?.facebook,
        },
        {
          icon: instaIcon,
          link: layoutData?.social_media?.instagram,
          data: layoutData?.social_media?.instagram,
        },
      ].filter((item) => item?.data),
    [layoutData?.social_media]
  );

  /**
   * Fetches layout data from the API
   */
  const getLayoutData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/layout`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Platform: 'web',
          'Accept-Language': 'ar',
        },
      });
      const data = await response.json();
      setLayoutData(data?.data);
    } catch (err) {
      console.error('err', err);
    }
  };

  /**
   * Handles the click event on the download button
   */
  const handleClickDownloadBtn = () => {
    setDownloadCount(prevCount => {
      const newCount = prevCount + 1;
      console.log('setDownloadCount:', newCount);
      return newCount;
    });
    window.fbq('track', 'InitiateCheckout', {
      content_name: 'App Download',
      content_type: 'button_click',
      value: layoutData?.platforms?.android_link || layoutData?.platforms?.ios_link,
    });
    getDeviceType() == 'android' ? window.open(layoutData?.platforms?.android_link, '_blank') : window.open(layoutData?.platforms?.ios_link, '_blank');
  };

  /**
   * Handles the click event on social media buttons
   * @param {Object} item - The social media item that was clicked
   */
  const handleClickSocialBtn = (item = {}) => {
    window.fbq('track', 'Contact', {
      content_name: 'Social Media Click',
      content_type: 'social_media',
      content_category: item?.data,
    });
  };

  useEffect(() => {
    getLayoutData();
    setViewCount(prevCount => {
      const newCount = prevCount + 1;
      console.log('setViewCount:', newCount);
      return newCount;
    });
  }, []);

  // Render desktop view if device type is desktop
  if (getDeviceType() == 'desktop') {
    return (
      <div className='text-[25px]  font-semibold relative w-full max-w-[1033px] mx-auto min-h-[100vh] flex flex-col justify-center  '>
        <img src={desktopImg} className='mx-auto w-full sm:max-w-[343px] max-w-[190px] mt-[173px]' alt='' />
        <p className='text-center mt-[3vh]'>عذرا .. هذا الموقع متاح عبر الموبايل فقط</p>
      </div>
    );
  }

  // Render mobile view
  return (
    <>
      <MetaPixel />
      <div className="container">
        {/* Background Section */}
        <div className="background"></div> 

        {/* Logo */}
        <div className="logo">
          <img src={cezmaLogo} style={{width: "10pc"}} alt="Logo" />
        </div>

        {/* Download Buttons */}
        <div className="download-buttons">
          <button onClick={handleClickDownloadBtn}>
            <img src={downloadButton}/>
          </button>
        </div>

        {/* Contact Section */}
        <div className="contact-section">
          <span className='text-[15px] font-regular'>لو حابب تتواصل معانا</span >
          <a href="https://api.whatsapp.com/send/?phone=201010066009&text=انا مستخدم من موقع سيزما، اريد الاستفسار عن شئ" className="button whatsapp-button">
            <img src={WhatsappButtonIcon} alt="WhatsApp" /> كلم خدمة العملاء واتساب
          </a>
          <a href="tel:00201010066009" className="button call-button">
            <img src={PhoneIcon} alt="Call" /> اتصل بخدمة العملاء
          </a>
        </div>

        {/* Shopping Illustration */}
        <div className="shopping-section">
          <img src={vectorImage} style={{width: "100%"}} alt="Shopping Illustration" />
        </div>

        {/* Social Media Links */}
        <footer className="social-media">
          <img src={text} alt="text" style={{width: "75%"}} />
          <div className="social-icons">
            {socialMedia.map((item, index) => (
              <a key={index} onClick={() => handleClickSocialBtn(item)} href={item.link} title='Find us' target='_blank' className='cursor-pointer rounded-full transition-all duration-300'>
                <img src={item.icon} alt='logo-ct' />
              </a>
            ))}
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
