const getDeviceType = () => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  if (isMobile) {
    if (isAndroid) {
      return 'android';
    } else if (isiOS) {
      return 'ios';
    } else {
      return false;
    }
  } else {
    return 'desktop';
  }
};
export default getDeviceType;
